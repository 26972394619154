.error-page {
   height: 100vh;
   width: 100vw;

   .clearfix {
      clear: both;
      zoom: 1;
   }

   .clearfix:before,
   .clearfix:after {
      content: '\0020';
      display: block;
      height: 0;
      visibility: hidden;
   }

   .clearfix:after {
      clear: both;
   }

   .plain.error-page-wrapper {
      font-family: 'Source Sans Pro', sans-serif;
      background-color: #f1f4f6;
      padding: 0 5%;
      position: relative;
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
   }

   .plain.error-page-wrapper .content-container {
      left: 0px;
      opacity: 1;
   }

   .plain.error-page-wrapper .head-line {
      transition: color 0.2s linear;
      font-size: 48px;
      line-height: 60px;
      color: rgba(255, 255, 255, 0.2);
      letter-spacing: -1px;
      margin-bottom: 5px;
   }
   .plain.error-page-wrapper .subheader {
      transition: color 0.2s linear;
      font-size: 36px;
      line-height: 46px;
      color: #fff;
   }

   .plain.error-page-wrapper hr {
      height: 1px;
      background-color: rgba(7, 25, 39, 1) !important;
      border: none;
      width: 250px;
      margin: 35px 0;
   }

   .plain.error-page-wrapper .context {
      transition: color 0.2s linear;
      font-size: 18px;
      line-height: 27px;
      color: #fff;
   }

   .plain.error-page-wrapper .context p {
      margin: 0;
   }

   .plain.error-page-wrapper .context p:nth-child(n + 2) {
      margin-top: 12px;
   }

   .plain.error-page-wrapper .buttons-container {
      margin-top: 45px;
      overflow: hidden;
   }

   .plain.error-page-wrapper .buttons-container a {
      transition: color 0.2s linear, border-color 0.2s linear;
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      border: 2px solid white;
      border-radius: 99px;
      padding: 8px 30px 9px;
      display: inline-block;
   }

   .plain.error-page-wrapper .buttons-container a:hover {
      background-color: rgba(255, 255, 255, 0.05);
   }

   .plain.error-page-wrapper .buttons-container a:first-child {
      margin-right: 25px;
   }

   @media screen and (max-width: 485px) {
      .plain.error-page-wrapper .header {
         font-size: 36px;
      }

      .plain.error-page-wrapper .subheader {
         font-size: 27px;
         line-height: 38px;
      }

      .plain.error-page-wrapper hr {
         width: 185px;
         margin: 25px 0;
      }

      .plain.error-page-wrapper .context {
         font-size: 16px;
         line-height: 24px;
      }

      .plain.error-page-wrapper .buttons-container {
         margin-top: 35px;
      }

      .plain.error-page-wrapper .buttons-container a {
         font-size: 13px;
         padding: 8px 0 7px;
         width: 45%;
         text-align: center;
      }

      .plain.error-page-wrapper .buttons-container a:first-child {
         margin-right: 10%;
      }
   }

   .background-color {
      background-color: #f1f4f6 !important;
   }

   .primary-text-color {
      color: rgba(7, 25, 39, 1) !important;
   }

   .secondary-text-color {
      color: rgba(29, 134, 204, 1) !important;
   }

   .sign-text-color {
      color: #ffba00 !important;
   }

   .sign-frame-color {
      color: #343c3f;
   }

   .pane {
      background-color: #ffffff !important;
   }

   .border-button {
      color: rgba(7, 25, 39, 1) !important;
      border-color: rgba(7, 25, 39, 1) !important;
   }

   .button {
      background-color: rgba(7, 25, 39, 1) !important;
      color: !important;
   }
}
