// Page title

.app-page-title {
   padding-left: $grid-gutter-width;
   padding-right: $grid-gutter-width;
   padding-bottom: $app-title-v-margin;
   padding-top: $app-title-v-margin;
   box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

   margin: (-$grid-gutter-width) (-$grid-gutter-width) ($grid-gutter-width);
   position: relative;

   .page-title-wrapper {
      position: relative;
      display: flex;
      align-items: center;
   }

   .page-title-heading,
   .page-title-subheading {
      margin: 0;
      padding: 0;
   }

   .page-title-heading {
      font-size: $h5-font-size;
      font-weight: 400;
      display: flex;
      align-content: center;
      align-items: center;
   }

   .page-title-subheading {
      padding: 3px 0 0;
      font-size: $font-size-base;
      opacity: 0.6;

      .breadcrumb {
         padding: 0;
         margin: 3px 0 0;
         background: transparent;
      }
   }

   .page-title-actions {
      margin-left: auto;

      .breadcrumb {
         margin: 0;
         padding: 0;
         background: transparent;
      }
   }

   .page-title-icon {
      font-size: 2rem;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      padding: ($layout-spacer-x / 1.8);
      margin: 0 ($grid-gutter-width) 0 0;
      background: $white;
      box-shadow: $box-shadow-default;
      @include border-radius($border-radius);
      width: 60px;
      height: 60px;

      .page-icon-default-color {
         color: #3ac47d;
      }

      i {
         margin: auto;
      }

      &.rounded-circle {
         margin: 0 ($grid-gutter-width / 1.5) 0 0;
      }
   }

   & + .RRT__container {
      margin-top: -($grid-gutter-width / 1.3);
   }

   & + .rc-tabs {
      // to fix width issue in firefox
      max-width: calc(100vw - 322px);
      margin: (-($grid-gutter-width)) (-($grid-gutter-width / 2)) (($grid-gutter-width * 1.3));
   }
   @media only screen and (max-width: 812px) and (min-width: 360px) {
      & + .rc-tabs {
         // to fix width issue in firefox
         max-width: calc(100vw - 0px);
         margin: (-($grid-gutter-width)) (-($grid-gutter-width / 2)) (($grid-gutter-width * 1.3));
      }
   }

   &.app-page-title-simple {
      margin: 0;
      background: none !important;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
   }
}

.page-title-icon-rounded {
   .page-title-icon {
      @include border-radius(50px);
   }
}

// Body Tabs

.body-tabs {
   &.body-tabs-layout {
      margin-left: -($grid-gutter-width);
      margin-right: -($grid-gutter-width);
      padding: 0 $grid-gutter-width;
      border-top: 0;
      border-bottom: $gray-300 solid 1px;
   }
}
