// Theme White

// Variables

$app-container-bg: #f1f4f6;
$app-sidebar-bg: #ffffff;
$app-header-bg: #fafbfc;
$app-header-logo-bg: rgba(255, 255, 255, 0.9);

// Content

.app-theme-white {
   &.app-container {
      background: $app-container-bg;
   }

   .app-sidebar {
      z-index: 999999;
      background: $app-sidebar-bg;
   }

   .app-page-title {
      background: rgba(255, 255, 255, 0.55);
   }

   .app-footer .app-footer__inner,
   .app-header {
      background: $app-header-bg;
   }

   &.fixed-header {
      .app-header__logo {
         background: rgba($app-header-bg, 0.1);
      }
   }
}
