// Header Base

.app-header {
   height: $app-header-height;
   display: flex;
   align-items: center;
   align-content: center;
   position: relative;
   z-index: 10;
   transition: all 0.2s;

   &.header-shadow {
      box-shadow: $box-shadow-default;
   }

   .app-header__content {
      display: flex;
      align-items: center;
      align-content: center;
      flex: 1;
      padding: 0 $layout-spacer-x;
      height: $app-header-height;

      .app-header-left {
         display: flex;
         align-items: center;
      }

      .app-header-right {
         align-items: center;
         display: flex;
         margin-left: auto;
      }
   }

   .header-user-info {
      & > .widget-heading,
      & > .widget-subheading {
         white-space: nowrap;
      }

      & > .widget-subheading {
         font-size: $font-size-xs;
      }
   }
}

.app-header__logo {
   padding: 0 $layout-spacer-x;
   height: $app-header-height;
   width: $app-sidebar-width;
   display: flex;
   align-items: center;
   transition: width 0.2s;

   .logo-src {
      height: $logo-height;
      width: $logo-width;
      background-image: url('utils/images/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
   }
}

.app-header__menu,
.app-header__mobile-menu {
   display: none;
   padding: 0 $layout-spacer-x;
   height: $app-header-height;
   align-items: center;
}

// Header Modifiers

@import 'modifiers/fixed-header';
@import 'modifiers/header-dots';
@import 'modifiers/header-megamenu';
@import 'modifiers/header-buttons';
//@import "modifiers/header-horizontal";

// Header Themes

@import 'themes/header-light';
@import 'themes/header-dark';
//@import "themes/header-inverted";
