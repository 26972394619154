// General

a,
button,
.btn {
   outline: none !important;
}

.app-container {
   display: flex;
   min-height: 100vh;
   flex-direction: column;
   margin: 0;
}
