// User Pages

.app-logo {
   height: $logo-height;
   width: $logo-width;
   background-image: url('utils/images/logo.png');
   background-size: contain;
   background-repeat: no-repeat;
}

.app-logo-inverse {
   height: $logo-height;
   width: $logo-width;
   background-image: url('utils/images/logo.png');
   background-size: contain;
   background-repeat: no-repeat;
}

.app-logo-login {
   height: 15vh;
   width: 100%;
   background-position: center;
   background-image: url('utils/images/logo.png');
   background-size: contain;
   background-repeat: no-repeat;
}

.app-login-box {
   .app-logo {
      margin-bottom: $layout-spacer-lg;
   }

   h4 {
      margin-bottom: $layout-spacer-x;
      font-weight: normal;

      div {
         opacity: 0.6;
      }

      span {
         font-size: $font-size-lg;
      }
   }
}
